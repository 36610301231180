import { ViewLarge, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import * as React from "react";
import { EGDSButtonSize, EGDSSecondaryButton } from "@egds/react-core/button";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSIcon } from "@egds/react-core/icons";

import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";

interface HotwireDealViewProp {
  label: string;
  buttonLink: string;
  modelKey: string;
  context: ExtendedContextStore;
}

interface CommonButtonProps {
  size: EGDSButtonSize;
  href: string;
  "data-testid": string;
}

interface DataTestIds {
  today: string;
  thisWeekend: string;
  nextWeekend: string;
}

const dataTestIds: DataTestIds = {
  today: "tonight",
  thisWeekend: "this-weekend",
  nextWeekend: "next-weekend",
};

export const HotwireDealView: React.FC<HotwireDealViewProp> = ({ label, buttonLink, modelKey, context }) => {
  const commonProps: CommonButtonProps = {
    size: EGDSButtonSize.LARGE,
    href: buttonLink,
    "data-testid": dataTestIds[modelKey as keyof DataTestIds],
  };

  return (
    <Viewport>
      <ViewSmall>
        <EGDSSpacing padding={{ inline: "unset" }} margin={{ blockstart: "one", blockend: "three" }}>
          <EGDSSecondaryButton {...commonProps} tag="a" isFullWidth>
            <EGDSIcon name="today" />
            {label}
          </EGDSSecondaryButton>
        </EGDSSpacing>
      </ViewSmall>
      <ViewLarge>
        <EGDSSpacing margin={{ blockstart: "one", inlineend: "three" }}>
          <EGDSSecondaryButton {...commonProps} tag="a">
            <EGDSIcon name="today" />
            {label}
          </EGDSSecondaryButton>
        </EGDSSpacing>
      </ViewLarge>
    </Viewport>
  );
};
