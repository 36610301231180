import * as React from "react";

import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";

import { ContainerProps } from "../Container";

export const HotwireContainer: React.FC<Omit<ContainerProps, "view">> = ({
  children,
  fmId,
  fmTitleId,
  id,
  title,
  subHeadline,
}) => (
  <div
    className="HotwireCheckPrices"
    id={id}
    data-fm={fmId}
    data-fm-title-id={fmTitleId}
    data-module="last-minute-deals"
  >
    <EGDSSpacing padding={{ block: "three" }}>
      <>
        <EGDSSpacing padding={{ block: "four" }}>
          <div>
            <EGDSHeading tag="h2" size={4}>
              {title}
            </EGDSHeading>
            {subHeadline && (
              <EGDSSpacing margin={{ blockstart: "one" }}>
                <EGDSText size={300} weight="regular">
                  {subHeadline}
                </EGDSText>
              </EGDSSpacing>
            )}
          </div>
        </EGDSSpacing>
        {children}
      </>
    </EGDSSpacing>
  </div>
);
