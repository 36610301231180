import { ILocalization } from "bernie-l10n";
import { CheckPricesLocalizationProps } from "./typings";
import { isPolygon } from "src/components/utility/LocationUtils";

// Localization utils
export const modelKeyToDealTextMap: any = {
  bestWeekend: (l10n: ILocalization) => l10n.formatText("checkPrices.title.bestDeal"),
  today: (l10n: ILocalization) => l10n.formatText("checkPrices.title.today"),
  tomorrow: (l10n: ILocalization) => l10n.formatText("checkPrices.title.tomorrowNight"),
  thisWeekend: (l10n: ILocalization) => l10n.formatText("checkPrices.title.thisWeekend"),
  nextWeekend: (l10n: ILocalization) => l10n.formatText("checkPrices.title.nextWeekend"),
  inTwoWeeks: (l10n: ILocalization) => l10n.formatText("checkPrices.title.inTwoWeeks"),
  inOneMonth: (l10n: ILocalization) => l10n.formatText("checkPrices.title.inOneMonth"),
  inTwoMonths: (l10n: ILocalization) => l10n.formatText("checkPrices.title.inTwoMonths"),
};

export const a11yKeyToTextMap: any = {
  bestWeekend: getLocalizationKey.bind(null, "bestDeal"),
  today: getLocalizationKey.bind(null, "today"),
  tomorrow: getLocalizationKey.bind(null, "tomorrowNight"),
  thisWeekend: getLocalizationKey.bind(null, "thisWeekend"),
  nextWeekend: getLocalizationKey.bind(null, "nextWeekend"),
  inTwoWeeks: getLocalizationKey.bind(null, "inTwoWeeks"),
  inOneMonth: getLocalizationKey.bind(null, "inOneMonth"),
  inTwoMonths: getLocalizationKey.bind(null, "inTwoMonths"),
};

function getLocalizationKey(key: string, { regionName, datesLabel, regionType, l10n }: CheckPricesLocalizationProps) {
  const shouldUseNearPreposition = !isPolygon(regionType);
  const closeTo = shouldUseNearPreposition ? ".closeTo" : "";

  return l10n.formatText(`checkPrices.title.${key}.title${closeTo}`, regionName, datesLabel);
}

// Functional Utils
export function buildDatesLabel(l10n: ILocalization, start: string, end: string): string {
  const formatDate = (date: string) => l10n.formatDateString(date, { skeleton: "MMMd" });

  return start && end ? `${formatDate(start)} - ${formatDate(end)}` : "";
}
