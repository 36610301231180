import * as React from "react";

import { EGDSCard, EGDSCardContentSection, EGDSCardContentSectionProps } from "@egds/react-core/cards";
import { EGDSLayoutGrid, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { a11yKeyToTextMap, buildDatesLabel, modelKeyToDealTextMap } from "../checkPricesLocalization";

import { DealProps } from "../typings";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { HotelSearchLinkBuilder } from "components/utility/HotelSearchLinkBuilder/HotelSearchLinkBuilder";
import { HotwireDealView } from "./views/HotwireDealView";
import { TrackedLink } from "src/components/utility/analytics/TrackedLink";
import { EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSText } from "@egds/react-core/text";
import { useLocalization } from "@shared-ui/localization-context";
import { viewContext } from "../CheckPrices";

export const Deal: React.FC<DealProps> = ({ border, modelKey, model, context }) => {
  // @ts-ignore
  if (!modelKeyToDealTextMap[modelKey] || !model[modelKey]) {
    return null;
  }

  const { searchContext } = context;
  // @ts-ignore
  const content = model[modelKey];
  const l10n = useLocalization();
  const { formatText } = l10n;
  const { name, type } = searchContext?.location || {};
  const datesLabel = buildDatesLabel(l10n, content.isoStartDate, content.isoEndDate);
  const data = model.dataType === "dates" ? datesLabel : content.price || formatText("checkPrices.label.getPrice");

  const cardLink = getCardLink(modelKey, content.link, context);

  const props = {
    a11yText: a11yKeyToTextMap[modelKey]({ regionName: name, datesLabel, regionType: type, l10n }),
    cardLink,
    label: modelKeyToDealTextMap[modelKey](l10n),
    data,
    modelKey,
    border,
  };

  const view = React.useContext(viewContext);

  switch (view) {
    case "horizontal-data-display":
      return <RowViewDeal {...props} />;
    case "horizontal-card-carousel":
      return <CarouselViewDeal {...props} />;
    case "hotwire":
      return (
        <HotwireDealView
          // @ts-ignore
          label={model[modelKey].label}
          // @ts-ignore
          buttonLink={model[modelKey].link}
          modelKey={modelKey}
          context={context}
        />
      );
    default:
      return <DealWrapper {...props} />;
  }
};

interface DealViewProp {
  a11yText: string;
  cardLink: string;
  data: string;
  label: string;
  modelKey: string;
  border?: boolean;
}

interface DealWrapperProp {
  a11yText: string;
  cardLink: string;
  data: string;
  label: string;
  modelKey: string;
  border?: boolean;
}

const getContentProps = (border: boolean): EGDSCardContentSectionProps => (border ? { border: "top" } : {});

const DealWrapper: React.FC<DealWrapperProp> = (props) => (
  <EGDSSpacing key={props.modelKey}>
    <EGDSCardContentSection {...getContentProps(!!props.border)}>
      <DefaultDeal {...props} />
    </EGDSCardContentSection>
  </EGDSSpacing>
);

const DefaultDeal: React.FC<DealViewProp> = ({ a11yText, cardLink, data, label, modelKey }) => (
  <>
    <TrackedLink
      tabIndex="0"
      moduleName="check-prices"
      rfrr={modelKey}
      href={cardLink}
      style={{ textDecoration: "none" }}
      data-testid={`checkprices-${label}-link`}
    >
      <div className={"is-visually-hidden"}>{a11yText}</div>
      <EGDSLayoutGrid columns={2} space="four">
        <EGDSLayoutGridItem>
          <EGDSText theme="default" size={300} className="uitk-text-default-theme">
            <div aria-hidden="true">{label}</div>
          </EGDSText>
        </EGDSLayoutGridItem>
        <EGDSLayoutGridItem justifySelf="end" alignSelf="center">
          <EGDSText theme="default" size={300}>
            <div aria-hidden="true">
              <a href={cardLink} tabIndex={-1} className="uitk-link">
                {data}
              </a>
            </div>
          </EGDSText>
        </EGDSLayoutGridItem>
      </EGDSLayoutGrid>
    </TrackedLink>
  </>
);

const RowViewDeal: React.FC<DealViewProp> = ({ a11yText, cardLink, data, label, modelKey }) => (
  <EGDSLayoutFlexItem grow={1}>
    <EGDSLayoutGrid>
      <EGDSText spacing="one" weight="bold" size={300}>
        {label}
      </EGDSText>
      <span className="is-visually-hidden">{a11yText}</span>
      <EGDSText size={300}>
        <TrackedLink moduleName="check-prices_row" rfrr={modelKey} href={cardLink}>
          {data}
        </TrackedLink>
      </EGDSText>
    </EGDSLayoutGrid>
  </EGDSLayoutFlexItem>
);

const CarouselViewDeal: React.FC<DealViewProp> = ({ a11yText, cardLink, data, label, modelKey }) => (
  <EGDSCardContentSection className="view-carousel-card">
    <EGDSCard>
      <EGDSCardContentSection>
        <div>
          <EGDSText size={300} weight="bold" align="center" spacing="one">
            <TrackedLink moduleName="check-prices_carouse" rfrr={modelKey} href={cardLink}>
              {data}
            </TrackedLink>
          </EGDSText>
          <EGDSText size={300} align="center" spacing="one">
            {label}
          </EGDSText>
          <span className="is-visually-hidden">{a11yText}</span>
        </div>
      </EGDSCardContentSection>
    </EGDSCard>
  </EGDSCardContentSection>
);

/**
 * This function handles creating the card link to HSR.
 * It is necessary because in the case of Today and Tomorrow, the back end module won't be returning the date as part of the URL
 * @param modelKey: to determine what deal we're dealing with.
 * @param backEndSuppliedLink: the url string built by the back-end module
 * @param context:
 */
const getCardLink = (modelKey: string, backEndSuppliedLink: string, context: ExtendedContextStore) => {
  const { formatDateString } = useLocalization();
  let cardLinkBuilder = new HotelSearchLinkBuilder(backEndSuppliedLink); // may not contain dates for today or tomorrow (timezone differences)
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  if (modelKey === "today") {
    cardLinkBuilder = new HotelSearchLinkBuilder(backEndSuppliedLink)
      .withStartDate(formatDateString(today, { raw: "yyyy-MM-dd" }))
      .withEndDate(formatDateString(tomorrow, { raw: "yyyy-MM-dd" }));
  }
  if (modelKey === "tomorrow") {
    const afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    cardLinkBuilder = new HotelSearchLinkBuilder(backEndSuppliedLink)
      .withStartDate(formatDateString(tomorrow, { raw: "yyyy-MM-dd" }))
      .withEndDate(formatDateString(afterTomorrow, { raw: "yyyy-MM-dd" }));
  }

  if (context.searchContext.lob === "vacation_rental") {
    cardLinkBuilder.withSorting(context.searchContext.lob.toUpperCase());
  }
  return cardLinkBuilder.build(context);
};
