import * as React from "react";
import { EGDSCard, EGDSCardContentSection } from "@egds/react-core/cards";
import { viewContext } from "../CheckPrices";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSText, EGDSHeading, EGDSSubheading } from "@egds/react-core/text";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { useLocalization } from "@shared-ui/localization-context";
import IsomorphicCarousel from "src/components/shared/IsomorphicCarousel/IsomorphicCarousel";
import { HotwireContainer } from "./views/HotwireContainer";

export interface ContainerProps {
  id: string;
  fmId: string;
  fmTitleId: number | null;
  title: string;
  view?: string;
  subHeadline: string;
  dataType: string;
}

export const Container: React.FC<ContainerProps> = (props) => {
  const view = React.useContext(viewContext);

  switch (view) {
    case "horizontal-data-display":
      return <RowViewContainer {...props} />;
    case "horizontal-card-carousel":
      return <CarouselViewContainer {...props} />;
    case "hotwire":
      return <HotwireContainer {...props} />;
    case "hasNoInlineSpacing":
      return <DefaultDLPContainer {...props} />;
    default:
      return <DefaultContainer {...props} />;
  }
};

const DefaultContainer: React.FC<Omit<ContainerProps, "view">> = ({
  children,
  fmId,
  fmTitleId,
  id,
  title,
  subHeadline,
}) => (
  <EGDSSpacing padding={{ block: "three", inline: "three" }}>
    <div className="CheckPrices" id={id} data-fm={fmId} data-fm-title-id={fmTitleId}>
      <EGDSCard padded>
        <EGDSLayoutFlex space="three" wrap="wrap">
          <EGDSLayoutFlexItem minWidth="full_width">
            <EGDSHeading tag="h2" size={4}>
              {title}
            </EGDSHeading>
          </EGDSLayoutFlexItem>
          <EGDSLayoutFlexItem minWidth="full_width">
            {subHeadline && (
              <EGDSText size={300} weight="regular" spacing="one">
                {subHeadline}
              </EGDSText>
            )}
          </EGDSLayoutFlexItem>
          <EGDSLayoutFlexItem minWidth="full_width">
            <EGDSCardContentSection padded={false}>{children}</EGDSCardContentSection>
          </EGDSLayoutFlexItem>
        </EGDSLayoutFlex>
      </EGDSCard>
    </div>
  </EGDSSpacing>
);

const RowViewContainer: React.FC<Omit<ContainerProps, "view">> = ({
  children,
  fmId,
  fmTitleId,
  id,
  title,
  subHeadline,
}) => (
  <EGDSSpacing padding={{ block: "three", inline: "four" }} margin="three">
    <div className="CheckPrices" id={id} data-fm={fmId} data-fm-title-id={fmTitleId}>
      {title && (
        <EGDSCard padded>
          <EGDSSpacing padding={{ blockend: "three" }}>
            <EGDSHeading tag="h2" size={5}>
              {title}
            </EGDSHeading>
          </EGDSSpacing>
          {subHeadline && (
            <EGDSSpacing margin={{ blockstart: "one" }}>
              <EGDSSubheading tag="p"> {subHeadline} </EGDSSubheading>
            </EGDSSpacing>
          )}
          {title ? (
            <EGDSSpacing padding={{ blockstart: "two" }}>
              <EGDSLayoutFlex space="six" wrap="wrap">
                {children}
              </EGDSLayoutFlex>
            </EGDSSpacing>
          ) : (
            <EGDSLayoutFlex space="six" wrap="wrap">
              {children}
            </EGDSLayoutFlex>
          )}
        </EGDSCard>
      )}
    </div>
  </EGDSSpacing>
);

const CarouselViewContainer: React.FC<Omit<ContainerProps, "view">> = ({
  children,
  fmId,
  fmTitleId,
  id,
  title,
  subHeadline,
}) => {
  const { formatText } = useLocalization();

  return (
    <EGDSSpacing padding="three">
      <section className="CheckPrices" id={id} data-fm={fmId} data-fm-title-id={fmTitleId}>
        <EGDSHeading tag="h2" size={5}>
          {title}
        </EGDSHeading>
        {subHeadline && (
          <EGDSText size={300} weight="regular" spacing="one">
            {subHeadline}
          </EGDSText>
        )}
        <EGDSSpacing margin={{ blockstart: "three" }}>
          <div>
            <IsomorphicCarousel
              className="CarouselView"
              carouselName="DestinationCards"
              buttonText={{
                nextButton: formatText("carousel.item.next"),
                prevButton: formatText("carousel.item.prev"),
              }}
              itemsVisible={{ sm: 2, md: 3, lg: 3 }}
              pageBy={1}
              peek
            >
              {children as React.ReactChild[]}
            </IsomorphicCarousel>
          </div>
        </EGDSSpacing>
      </section>
    </EGDSSpacing>
  );
};

const DefaultDLPContainer: React.FC<Omit<ContainerProps, "view">> = ({
  children,
  fmId,
  fmTitleId,
  id,
  title,
  subHeadline,
}) => (
  <EGDSSpacing padding={{ block: "three" }}>
    <div className="CheckPrices" id={id} data-fm={fmId} data-fm-title-id={fmTitleId}>
      <EGDSCard padded>
        <EGDSLayoutFlex space="three" wrap="wrap">
          <EGDSLayoutFlexItem minWidth="full_width">
            <EGDSHeading tag="h2" size={4}>
              {title}
            </EGDSHeading>
          </EGDSLayoutFlexItem>
          <EGDSLayoutFlexItem minWidth="full_width">
            {subHeadline && (
              <EGDSText size={300} weight="regular" spacing="one">
                {subHeadline}
              </EGDSText>
            )}
          </EGDSLayoutFlexItem>
          <EGDSLayoutFlexItem minWidth="full_width">
            <EGDSCardContentSection padded={false}>{children}</EGDSCardContentSection>
          </EGDSLayoutFlexItem>
        </EGDSLayoutFlex>
      </EGDSCard>
    </div>
  </EGDSSpacing>
);
