import * as React from "react";
import { CheckPricesProps } from "./typings";
import { CheckPricesFlexModuleResult } from "typings/microserviceModels/check-prices-flex-module";
import { ItemKeyHelper } from "src/components/utility/ItemKeyHelper";
import { Container } from "./component/Container";
import { Deal } from "./component/Deal";
import { withStores } from "stores";
import { observer } from "mobx-react";
import { getFmId } from "src/stores/ExperienceTemplateStore";

const checkPricesKey = new ItemKeyHelper("check-prices");
// TODO the back-end should have control over what is being rendered as a deal. Right now it follows an anti-pattern of sending a whole bunch of data without proper hierarchy:
//  https://github.expedia.biz/Flex/check-prices-flex-module/blob/master/src/main/java/com/expedia/www/check/prices/module/CheckPricesFlexModuleResult.java
let supportedDeals;

export const viewContext = React.createContext<string | undefined>("");
const ViewProvider = viewContext.Provider;

export const CheckPrices = withStores(
  "flexModuleModelStore",
  "context"
)(
  observer((props: CheckPricesProps) => {
    const { context, templateComponent, flexModuleModelStore } = props;

    if (!templateComponent) {
      return null;
    }

    const {
      metadata: { id },
      config: { fmTitleId, view },
    } = templateComponent;
    const fmId = getFmId(templateComponent);
    const model = flexModuleModelStore.getModel(id) as CheckPricesFlexModuleResult | null;

    /* istanbul ignore next */
    if (!model) {
      return null;
    }
    if (model.lobType === "vacation_rental") {
      supportedDeals = ["nextWeekend", "inTwoWeeks", "inOneMonth", "inTwoMonths"];
    } else {
      supportedDeals = ["today", "tomorrow", "thisWeekend", "nextWeekend", "bestWeekend"];
    }
    const availableSupportedDeals = supportedDeals.filter((deal) => deal in model);

    const containerProps = {
      title: model.title,
      id,
      fmId,
      fmTitleId: fmTitleId || null,
      view,
      subHeadline: model.subHeadline,
      dataType: model.dataType,
    };

    return (
      <ViewProvider value={view}>
        <Container {...containerProps}>
          {availableSupportedDeals.map((modelKey, index) => (
            <Deal key={checkPricesKey.next()} modelKey={modelKey} model={model} context={context} border={true} />
          ))}
        </Container>
      </ViewProvider>
    );
  })
);

export default CheckPrices;
